<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 저장 -->
                <c-btn
                  v-show="!disabled && editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="patrol"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :disabled="disabled"
                  :editable="editable && !isOld" 
                  type="edit" 
                  name="plantCd" 
                  v-model="patrol.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 제목 -->
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="제목"
                  name="patrolName"
                  v-model="patrol.patrolName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 평가일시 -->
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="평가일시"
                  :minuteStep="10"
                  type="datetime"
                  name="patrolDate"
                  v-model="patrol.patrolDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-multi-field
                  :editable="editable"
                  :data="patrol"
                  :disabled="disabled"
                  label="평가자"
                  type="user"
                  name="patrolUserIds"
                  v-model="patrol.patrolUserIds">
                </c-multi-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 중점 평가 내용 -->
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="중점 평가 내용"
                  name="patrolResultSummary"
                  v-model="patrol.patrolResultSummary"
                >
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-tab
            :dense="true"
            :tabItems.sync="tabItems"
            type="tabcard"
            :inlineLabel="true"
            v-model="tab"
          >
            <template v-slot:default="tab">
              <component
                :popupParam.sync="popupParam"
                :tabItems.sync="tabItems"
                :grid.sync="grid"
                :deptData.sync="tab.deptData"
                :patrol.sync="patrol"
                :is="tab.component"
                @setDeptList="getDeptList"
              />
            </template>
          </c-tab>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'patrolInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        isApprContent: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        height: "360px",
        data: [],
      },
      patrol: {
        saiPatrolId: '',
        plantCd: '',
        patrolDate: '',
        patrolName: '',
        patrolResultSummary: '',
        patrolCompleteFlag: '',
        patrolUserIds: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',
        chgUserId: '',
        results: [],
        deleteResults: [],
      },
      tab: '',
      deptUrl: '',
      tabItems: [],
      editable: true,
      saveType: 'POST',
      resultItemUrl: '',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isApproval: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.saiPatrolId)
    },
    disabled() {
      return this.patrol.patrolCompleteFlag === "Y"
        // 결재중인 경우 비활성화
        || (this.patrol.approvalStatusCd === 'ASC0000001' && !this.popupParam.isApprContent)
    },
    // [E] 결재관련 버튼 컨트롤
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.deptUrl = selectConfig.mdm.dept.list.url;
      this.detailUrl = selectConfig.sai.patrol.get.url;
      this.saveUrl = transactionConfig.sai.patrol.insert.url;
      this.deleteUrl = transactionConfig.sai.patrol.delete.url;
      this.approvalUrl = transactionConfig.sai.patrol.update.url;
      // code setting
      this.getDetail();
      this.setHeader();
      // list setting
    },
    setHeader() {
      this.$comm.uploderSetting('FIVES_PATROL_RESULT').then(_result => {
        this.grid.columns = [
          {
            required: true,
            name: 'mdmLocationId',
            field: 'mdmLocationId',
            // 장소
            label: '장소',
            align: 'center',
            style: 'width:160px',
            type: 'location',
            sortable: false
          },
          {
            required: true,
            name: 'patrolContents',
            field: 'patrolContents',
            // 지적사항
            label: '지적사항',
            align: 'left',
            type: 'textarea',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '도해<br>(지적사항에 대한 사진)',
            align: 'center',
            style: 'width:100px',
            type: 'attach',
            customSize: 'width: 70px !important;height: 70px !important',
            taskClassCd: 'FIVES_PATROL_RESULT',
            keyText: 'saiPatrolResultId',
            uploaderSetting: _result,
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            name: 'patrolRequestDate',
            field: 'patrolRequestDate',
            label: '요청일',
            align: 'center',
            style: 'width:100px',
            type: 'date',
            sortable: false
          },
          {
            label: '개선조치[조치 일정 준수]',
            align: 'center',
            child: [
              {
                name: 'actionContents',
                field: 'actionContents',
                label: '조치내역',
                align: 'left',
                style: 'width:150px',
                sortable: false
              },
              {
                name: 'downPath',
                field: 'downPath',
                label: '도해(조치 후 사진)',
                align: 'center',
                style: 'width:100px',
                type: 'img',
                customSize: 'width: 70px !important;height: 70px !important',
                // taskClassCd: 'IBM_AFTER',
                // keyText: 'sopImprovementId',
                sortable: false,
              },
              {
                name: 'actionCompleteDate',
                field: 'actionCompleteDate',
                label: '완료일',
                align: 'center',
                style: 'width:80px',
                sortable: false
              },
              {
                name: 'link',
                field: 'link',
                label: '개선상세',
                align: 'center',
                type: 'custom',
                style: 'width:50px',
                sortable: false
              },
            ]
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            align: 'left',
            type: 'textarea',
            style: 'width:120px',
            sortable: false,
          },
        ]
      })
    },
    getDeptList() {
      this.$http.url = this.deptUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        fivesFlag: 'Y',
      };
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          this.$_.forEach(_result.data, _item => {
            let idx = this.$_.findIndex(this.tabItems, {name: _item.deptCd})
            if (idx === -1) {
              this.tabItems.push({
                name: _item.deptCd, 
                icon: 'bookmark', label: _item.deptName,
                deptData: {
                  deptCd: _item.deptCd,
                  deptName: _item.deptName,
                  results: this.$_.filter(this.patrol.results, {deptCd: _item.deptCd})
                },
                component: () => import(`${'@/pages/sai/patrol/patrolDeptResult.vue'}`) 
              })
            } else {
              this.$set(this.tabItems[idx], 'deptData', 
              {
                deptCd: _item.deptCd,
                deptName: _item.deptName,
                results: this.$_.filter(this.patrol.results, {deptCd: _item.deptCd})
              })
            }
          })
          if (!this.tab) {
            this.tab = _result.data[0].deptCd
          }
        }
      },);

    },
    getDetail() {
      if (this.popupParam.saiPatrolId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiPatrolId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.patrol = _result.data;
          this.$emit('setRegInfo', _result.data)
          this.getDeptList();
        },);
      } else {
        this.patrol.patrolDate = this.$comm.getToday();
        this.getDeptList();
      }
    },
    savePlan() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sai.patrol.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.patrol.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (this.$comm.validTable(this.grid.columns, this.patrol.results, '', true)) {
          if (_result) { 
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.patrol.regUserId = this.$store.getters.user.userId
                this.patrol.chgUserId = this.$store.getters.user.userId

                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'saiPatrolId', _result.data)
      }
      this.getDetail();
    },
  }
};
</script>
